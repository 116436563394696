<template>
  <section class="invoice-preview-wrapper">
    <b-card>
      <b-row>
        <b-col xl="12" lg="12" md="12" sm="12" xs="12">
          <div class="card-body ">
            <div class="d-sm-flex align-items-center">
              <!-- Utilize "d-sm-flex" para aplicar flex apenas a partir do breakpoint "sm" -->
              <h5 class="mb-3 mb-sm-0 custom-title">🎫 {{ $t('Model') }}: <strong class="mr-2">{{ modelInfo }}</strong>
                {{ $t('Color') }}: <strong style="margin-left: 1em; margin-right:1em">{{ color }}</strong>
                {{ $t('Identifier') }}: <strong>{{ identifier }}</strong></h5>
              <b-dropdown :text="month" block split split-variant="outline-dark" variant="dark"
                          class="mr-2 ml-auto mb-2 mb-sm-0">
                <b-dropdown-item :key="opt.value" v-for="opt in optMonth" @click="selectMonth(opt.value,opt.text)">{{ opt.text }}
                </b-dropdown-item>
              </b-dropdown>
              <b-button v-b-tooltip.hover :title="tooltipBtn" size="sm" variant="primary" @click="exportSummary">
                <feather-icon icon="DownloadIcon" size="16"/>
              </b-button>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-col xl="12" md="12" sm="12" v-show="isLoadingDaily == true">
        <div class="text-center mb-2 mt-2">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
      </b-col>
      <b-col xl="12" md="12" sm="12" v-show="showMsgNoDataFound">
        <div class="text-center mb-2 mt-2">
          <h4>Nenhum dado encontrado</h4>
        </div>
      </b-col>
      <div class="app-calendar overflow-hidden border d-none d-md-block" v-if="showCalendar">
        <div class="row no-gutters">
          <!-- Calendar  isLoadingDaily -->
          <div class="col position-relative">
            <div class="card shadow-none border-0 mb-0 rounded-0">
              <div class="card-body pb-0" :class="{'calendar-hidden': !showCalendar}">
                <full-calendar
                    :key="calendarKey"
                    ref="refCalendar"
                    :options="calendarOptions"
                    class="full-calendar text-capitalize"

                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <b-row>
        <!--Chart daily work hours-->
        <b-col xl="12" md="12" sm="12">
          <monthly-telemetry-chart v-if="axisY.length > 0 && isLoadingDaily == false && axisY.some(valor => valor > 0)"
                                   :title="this.$t('Working Hours')" :axisX="axisX" :axisY="axisY"
                                   :unit="this.$t('Worked Hours')"/>
          <div class="text-center mb-2 mt-2">
            <b-spinner v-show="isLoadingDaily == true" variant="primary" label="Spinning"></b-spinner>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <!--Chart Fuel-->
        <b-col xl="12" md="12" sm="12">
          <monthly-telemetry-chart :title="$t('Level Fuel')" :axisX="fuelAxisX" :axisY="fuelAxisY" unit="%"
                                   v-if="fuelAxisY.length > 0 && isLoadingDaily == false && fuelAxisY.some(valor => valor > 0)"/>
          <div class="text-center mb-2 mt-2">
            <b-spinner v-show="isLoadingDaily == true" variant="primary" label="Spinning"></b-spinner>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <!--Chart consumption-->
        <b-col xl="12" md="12" sm="12">
          <monthly-telemetry-chart :title="$t('Average Fuel Consumption')" :axisX="consumptionAxisX"
                                   :axisY="consumptionAxisY" unit="L/h"
                                   v-if="consumptionAxisY.length > 0 && isLoadingDaily == false && consumptionAxisY.some(valor => valor > 0)"/>
          <div class="text-center mb-2 mt-2">
            <b-spinner v-show="isLoadingDaily == true" variant="primary" label="Spinning"></b-spinner>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="12" md="12" sm="12">
          <monthly-telemetry-chart :title="$t('Battery Voltage')" :axisX="batteryVoltageAxisX"
                                   :axisY="batteryVoltageAxisY" unit="V"
                                   v-if="batteryVoltageAxisY.length > 0 && isLoadingDaily == false && batteryVoltageAxisY.some(valor => valor > 0)"/>
          <div class="text-center mb-2 mt-2">
            <b-spinner v-show="isLoadingDaily == true" variant="primary" label="Spinning"></b-spinner>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="12" md="12" sm="12">
          <monthly-telemetry-chart :title="$t('Day errors')" :axisX="dayErrorsAxisX" :axisY="dayErrorsAxisY" unit=""
                                   v-if="dayErrorsAxisY.length > 0 && isLoadingDaily == false && dayErrorsAxisY.some(valor => valor > 0)"/>
          <div class="text-center mb-2 mt-2">
            <b-spinner v-show="isLoadingDaily == true" variant="primary" label="Spinning"></b-spinner>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="12" md="12" sm="12">
          <monthly-telemetry-chart :title="$t('Fuel Pressure')" :axisX="fuelPressureAxisX" :axisY="fuelPressureAxisY"
                                   unit="kPa" v-if="fuelPressureAxisY.length > 0 && isLoadingDaily == false && fuelPressureAxisY.some(valor => valor > 0)"/>
          <div class="text-center mb-2 mt-2">
            <b-spinner v-show="isLoadingDaily == true" variant="primary" label="Spinning"></b-spinner>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="12" md="12" sm="12">
          <monthly-telemetry-chart :title="$t('Engine Load')" :axisX="engineLoadAxisX" :axisY="engineLoadAxisY" unit="%"
                                   v-if="engineLoadAxisY.length > 0 && isLoadingDaily == false && engineLoadAxisY.some(valor => valor > 0)"/>
          <div class="text-center mb-2 mt-2">
            <b-spinner v-show="isLoadingDaily == true" variant="primary" label="Spinning"></b-spinner>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="12" md="12" sm="12">
          <monthly-telemetry-chart :title="$t('Engine Oil Pressure')" :axisX="engineOilPressureAxisX"
                                   :axisY="engineOilPressureAxisY" unit="kPa"
                                   v-if="engineOilPressureAxisY.length > 0 && isLoadingDaily == false && engineOilPressureAxisY.some(valor => valor > 0)"/>
          <div class="text-center mb-2 mt-2">
            <b-spinner v-show="isLoadingDaily == true" variant="primary" label="Spinning"></b-spinner>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="12" md="12" sm="12">
          <monthly-telemetry-chart :title="$t('Engine Coolant Temperature')" :axisX="engineCoolantTempAxisX"
                                   :axisY="engineCoolantTempAxisY" unit="ºC"
                                   v-if="engineCoolantTempAxisY.length > 0 && isLoadingDaily == false && engineCoolantTempAxisY.some(valor => valor > 0)"/>
          <div class="text-center mb-2 mt-2">
            <b-spinner v-show="isLoadingDaily == true" variant="primary" label="Spinning"></b-spinner>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="12" md="12" sm="12">
          <monthly-telemetry-chart :title="$t('Hydraulic Oil Pressure')" :axisX="hydraulicPressureAxisX"
                                   :axisY="hydraulicPressureAxisY" unit="kPa"
                                   v-if="hydraulicPressureAxisY.length > 0 && isLoadingDaily == false && hydraulicPressureAxisY.some(valor => valor > 0)"/>
          <div class="text-center mb-2 mt-2">
            <b-spinner v-show="isLoadingDaily == true" variant="primary" label="Spinning"></b-spinner>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="12" md="12" sm="12">
          <monthly-telemetry-chart :title="$t('Hydraulic Oil Temperature')" :axisX="hydraulicOilTempAxisX"
                                   :axisY="hydraulicOilTempAxisY" unit="ºC"
                                   v-if="hydraulicOilTempAxisY.length > 0 && isLoadingDaily == false && hydraulicOilTempAxisY.some(valor => valor > 0)"/>
          <div class="text-center mb-2 mt-2">
            <b-spinner v-show="isLoadingDaily == true" variant="primary" label="Spinning"></b-spinner>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="12" md="12" sm="12">
          <monthly-telemetry-chart :title="$t('Power Battery Charge')" :axisX="powerBatteryChargeAxisX"
                                   :axisY="powerBatteryChargeAxisY" unit="%"
                                   v-if="powerBatteryChargeAxisY.length > 0 && isLoadingDaily == false && powerBatteryChargeAxisY.some(valor => valor > 0)"/>
          <div class="text-center mb-2 mt-2">
            <b-spinner v-show="isLoadingDaily == true" variant="primary" label="Spinning"></b-spinner>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="12" md="12" sm="12">
          <monthly-telemetry-chart :title="$t('Power Battery Life')" :axisX="powerBatteryLifeAxisX"
                                   :axisY="powerBatteryLifeAxisY" unit="%"
                                   v-if="powerBatteryLifeAxisY.length > 0 && isLoadingDaily == false && powerBatteryLifeAxisY.some(valor => valor > 0)"/>
          <div class="text-center mb-2 mt-2">
            <b-spinner v-show="isLoadingDaily == true" variant="primary" label="Spinning"></b-spinner>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="12" md="12" sm="12">
          <monthly-telemetry-chart :title="$t('Power Battery Temperature')" :axisX="powerBatteryTemperatureAxisX"
                                   :axisY="powerBatteryTemperatureAxisY" unit="ºC"
                                   v-if="powerBatteryTemperatureAxisY.length > 0 && isLoadingDaily == false && powerBatteryTemperatureAxisY.some(valor => valor > 0)"/>
          <div class="text-center mb-2 mt-2">
            <b-spinner v-show="isLoadingDaily == true" variant="primary" label="Spinning"></b-spinner>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="12" md="12" sm="12">
          <monthly-telemetry-chart :title="$t('Power Battery Volts')" :axisX="powerBatteryVoltsAxisX"
                                   :axisY="powerBatteryVoltsAxisY" unit="ºC"
                                   v-if="powerBatteryVoltsAxisY.length > 0 && isLoadingDaily == false && powerBatteryVoltsAxisY.some(valor => valor > 0)"/>
          <div class="text-center mb-2 mt-2">
            <b-spinner v-show="isLoadingDaily == true" variant="primary" label="Spinning"></b-spinner>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="12" md="12" sm="12">
          <monthly-telemetry-chart :title="$t('Speed')" :axisX="speedAxisX" :axisY="speedAxisY" unit="Km/h"
                                   v-if="speedAxisY.length > 0 && isLoadingDaily == false && speedAxisY.some(valor => valor > 0)"/>
          <div class="text-center mb-2 mt-2">
            <b-spinner v-show="isLoadingDaily == true" variant="primary" label="Spinning"></b-spinner>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="12" md="12" sm="12">
          <monthly-telemetry-chart :title="$t('Transmission Oil Temperature')" :axisX="transmissionTempAxisX"
                                   :axisY="transmissionTempAxisY" unit="ºC"
                                   v-if="transmissionTempAxisY.length > 0 && isLoadingDaily == false && transmissionTempAxisY.some(valor => valor > 0)"/>
          <div class="text-center mb-2 mt-2">
            <b-spinner v-show="isLoadingDaily == true" variant="primary" label="Spinning"></b-spinner>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="12" md="12" sm="12">
          <monthly-telemetry-chart :title="$t('Turbo Pressure')" :axisX="turboPressureAxisX" :axisY="turboPressureAxisY"
                                   unit="kPa" v-if="turboPressureAxisY.length > 0 && isLoadingDaily == false && turboPressureAxisY.some(valor => valor > 0)"/>
          <div class="text-center mb-2 mt-2">
            <b-spinner v-show="isLoadingDaily == true" variant="primary" label="Spinning"></b-spinner>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <!--Chart status-->
        <b-col sm="12" md="6">
          <chart-status v-if="statusValue.length > 0 && isLoadingDaily == false && statusValue.some(valor => valor > 0)" :labels="statusLabels"
                        :values="statusValue"/>
          <div class="text-center mb-2 mt-2">
            <b-spinner v-show="isLoadingDaily == true" variant="primary" label="Spinning"></b-spinner>
          </div>
        </b-col>
<!--        <b-col sm="12" md="6">-->
<!--          <chart-percentages v-if="metricsPercentages.length > 0 && isLoadingDaily == false && metricsPercentages.some(valor => valor > 0)" :labels="metricsLabels"-->
<!--                             :values="metricsPercentages"/>-->
<!--          <div class="text-center mb-2 mt-2">-->
<!--            <b-spinner v-show="isLoadingDaily == true" variant="primary" label="Spinning"></b-spinner>-->
<!--          </div>-->
<!--        </b-col>-->
      </b-row>
      <b-row>
        <!--map-->
        <b-col xl="12" md="12" sm="12" v-show="latlngs.length > 0 && isLoading == false ">
          <b-card>
            <div class="heat-map">
              <l-map ref="myMap" style="height: 100%; overflow: hidden; max-height: 500px; min-height: 500px;" :zoom="8"
                     :center="center" :key="latlngs.length">
                <l-tile-layer :url="url"></l-tile-layer>
                <l-control>
                  <!-- size -->
                  <b-button-group size="sm">
                    <b-button variant="light" :pressed="btnMap" @click="chooseLayer('map')">
                      Map
                    </b-button>
                    <b-button variant="light" :pressed="btnGround" @click="chooseLayer('ground')">
                      Ground
                    </b-button>
                    <b-button variant="light" :pressed="btnHybrid" @click="chooseLayer('hybrid')">
                      Hybrid
                    </b-button>
                  </b-button-group>
                </l-control>
                <Vue2LeafletHeatmap v-if="latlngs.length > 0 && isLoading == false" :lat-lng="latlngs" :radius="60"
                                    :min-opacity=".75" :max-zoom="4" :blur="60"></Vue2LeafletHeatmap>
              </l-map>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <div v-show="isLoading" class="text-center mb-3 mt-3">
        <b-spinner variant="primary" label="Text Centered"/>
      </div>
    </b-card>

    <!--Modal Create Export-->
    <b-modal ref="modal-export" size="sm" centered hide-header hide-footer no-close-on-esc no-close-on-backdrop
             hide-header-close>
      <div class="mx-3 text-center">
        <h3>{{ $t('Preparing report') }}...</h3>
        <b-spinner variant="primary" label="Text Centered"/>
      </div>
    </b-modal>

  </section>
</template>

<script>
import WorkHours from '../telemetry/components/ChartDailyWorkHours.vue'
import ChartStatus from './components/ChartStatus.vue'
import ChartPercentages from './components/ChartPercentages.vue'
import ChartConsumption from './components/ChartConsumption.vue'
import MonthlyTelemetryChart from './components/MonthlyTelemetryChart.vue'

import {
  ref,
  onUnmounted
} from '@vue/composition-api'
import store from '@/store'
import router from '@/router'

import queriesStoreModule from '../queriesStoreModule'

import {
  BTabs,
  BTab,
  BCardText,
  BRow,
  BCol,
  BCard,
  BDropdown,
  BDropdownItem,
  BSpinner,
  BButtonGroup,
  BButton
} from 'bootstrap-vue'
import ChartFuel from './components/ChartFuel.vue'

import {
  LMap,
  LTileLayer,
  LControl,
} from "vue2-leaflet";

import 'leaflet/dist/leaflet.css'
import Vue2LeafletHeatmap from "@core/components/heat-map/Vue2LeafletHeatmap";

import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'

import interactionPlugin from '@fullcalendar/interaction'
import '@fullcalendar/core/locales/pt-br'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {

  components: {
    MonthlyTelemetryChart,
    WorkHours,
    BTabs,
    BTab,
    BCardText,
    BRow,
    BCol,
    BCard,
    BDropdown,
    BDropdownItem,
    BSpinner,
    ChartStatus,
    ChartPercentages,
    ChartConsumption,
    ChartFuel,
    BButtonGroup,
    BButton,//

    LMap,
    LTileLayer,
    Vue2LeafletHeatmap,
    LControl,
    FullCalendar
  },
  data() {
    return {
      calendarKey: 1,
      tooltipBtn: this.$t('Download Summary'),
      optMonth: [],
      month: ``,
      dtS: '',
      dtE: ``,
      isLoading: true,
      isLoadingDaily: true,
      center: [0, 0],

      latlngs: [],
      latlngs2: [
        [-19.82001, -43.99717, 0.5],
        [-19.80377, -43.98359, 0.5],
      ],
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',

      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: 'dayGridMonth',
        weekends: true,
        editable: false,
        droppable: false,
        eventResizableFromStart: true,
        headerToolbar: {
          left: '',
          center: '',
          right: '',
        },

        //initialEvents: [],
        events: [],
        eventClick: this.handleEventClick,
        locale: 'pt-br',
        showMsgNoDataFound: false,
        showCalendar: false

        //currentEvents: this.myEvents
      },

    };
  },
  computed: {},
  created() {

    const dt = this.incrementMonth(-1)
    this.month = `${(dt.getMonth() + 1) < 10 ? '0' : ''}${(dt.getMonth() + 1)}/${dt.getFullYear()}`
    this.populateDropdownMonth()

    this.startDates()

    this.fetchDailyWorkHours()
    this.fetchAsset()

  },
  mounted() {
    this.fetchPlaces()
    if (localStorage.layerHeatMap) {
      this.chooseLayer(localStorage.layerHeatMap)
    }

    // Acessar a instância do calendário e atualizá-la
    this.$nextTick(() => {
      this.calendarKey++
      this.$refs.refCalendar.getApi().gotoDate(this.incrementMonth(-1))
    })
  },

  methods: {

    handleEventClick(info) {
      const eventTitle = info.event.title;
      console.log('Título do evento clicado:', eventTitle);
    },

    showModalExport() {
      this.$refs['modal-export'].show()
    },
    hideModalExport() {
      this.$refs['modal-export'].hide()
    },

    async exportSummary() {
      this.showModalExport()
      const res = await store.dispatch('app-queries/exportMonthlySummaryToExcel', {
        assetId: this.myId,
        start: this.dtS,
        end: this.dtE

      })
          .then((response) => {
            let date = new Date(this.dtS);
            let month = date.getMonth() + 1;

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `monthly_summary_${month}.xls`);
            document.body.appendChild(link);
            link.click();
            //this.exportPdf = false
            this.hideModalExport()
          });
    },

    chooseLayer(url) {
      localStorage.layerHeatMap = url;

      if (url == `ground`) {
        this.btnGround = true
        this.btnMap = false
        this.btnHybrid = false
        this.url = "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}";
      }
      if (url == `map`) {
        this.btnGround = false
        this.btnMap = true
        this.btnHybrid = false
        this.url = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
      }
      if (url == `hybrid`) {
        this.btnGround = false
        this.btnMap = false
        this.btnHybrid = true
        this.url = "https://1.aerial.maps.api.here.com/maptile/2.1/maptile/newest/hybrid.day/{z}/{x}/{y}/256/png8?lg=por&app_id=adPipTPyffSRGrMtXttz&app_code=oNecq1kBuaikCv_pYw20yg";
      }
      //else this.url = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
    },

    resolveIcon: assetType => {
      console.log('--- asset ---', assetType);
      if (assetType === 1) return require('@/assets/images/assets/Car.png')
      if (assetType === 2) return require('@/assets/images/assets/Motorcycle.png')
      if (assetType === 3) return require('@/assets/images/assets/Truck.png')
      if (assetType === 4) return require('@/assets/images/assets/TruckTractor.png')
      if (assetType === 5) return require('@/assets/images/assets/Van.png')
      if (assetType === 6) return require('@/assets/images/assets/Bus.png')
      if (assetType === 7) return require('@/assets/images/assets/Pickup.png')
      if (assetType === 8) return require('@/assets/images/assets/Excavator.png')
      if (assetType === 9) return require('@/assets/images/assets/Grader.png')
      if (assetType === 10) return require('@/assets/images/assets/WheelLoader.png')
      if (assetType === 11) return require('@/assets/images/assets/Dozer.png')
      if (assetType === 12) return require('@/assets/images/assets/BackhoeLoader.png')
      if (assetType === 13) return require('@/assets/images/assets/SkidSteerLoader.png')
      if (assetType === 14) return require('@/assets/images/assets/Boat.png')
      if (assetType === 15) return require('@/assets/images/assets/JetSki.png')
      if (assetType === 16) return require('@/assets/images/assets/BoxTruck.png')
      if (assetType === 17) return require('@/assets/images/assets/MixerTruck.png')
      if (assetType === 18) return require('@/assets/images/assets/WaterTruck.png')
      if (assetType === 19) return require("@/assets/images/assets/CraneTruck.png");
      if (assetType === 20) return require("@/assets/images/assets/RoadRoller.png");
      if (assetType === 21) return require("@/assets/images/assets/ForkLift.png");
      if (assetType === 22) return require("@/assets/images/assets/ScissorLift.png");
      if (assetType === 23) return require("@/assets/images/assets/ForkLiftCabin.png");
      if (assetType === 24) return require("@/assets/images/assets/ContainerLifter.png");
      if (assetType === 25) return require("@/assets/images/assets/ArticulatedLift.png");
      if (assetType === 26) return require("@/assets/images/assets/CombineHarvester.png");
      if (assetType === 27) return require("@/assets/images/assets/SealcoatTruck.png");
      if (assetType === 28) return require("@/assets/images/assets/IrrigationPivot.png");
      if (assetType === 29) return require("@/assets/images/assets/SecurityHelmet.png");
      if (assetType === 30) return require("@/assets/images/assets/CropSprayer.png");

      return require('@/assets/images/assets/Car.png')

    },

    async fetchPlaces() {

      this.isLoading = true
      this.latlngs = []
      const resp = await store
          .dispatch('app-queries/fetchPlaces', {
            id: router.currentRoute.params.id,
            dtS: this.dtS,
            dtE: this.dtE
          });
      const respData = await resp.data

      if (respData) {
        respData.places.map((node, i) => {

          console.log(`LAT PLACE `, node.lat)
          if (node.lat && node.lat != 0 && node.lng && node.lng != 0) this.latlngs.push([node.lat, node.lng, 0.5])

        });

        this.$nextTick(() => {
          //this.$refs.myMap.mapObject.setView(this.latLong, 17);
          //this.$refs.myMap.mapObject.setView(this.latLong);
          this.$refs.myMap.mapObject.fitBounds(respData.places.map(m => {
            return [m.lat, m.lng]
          }))

        })
      }

      this.isLoading = false

    },
    formatDate(date) {
      const day = date.getDate()
      const month = date.getMonth() + 1
      return `${day < 10 ? '0' : ''}${day}/${month < 10 ? '0' : ''}${month}`
    },

    needShowMsgNoDataFound() {
      this.showMsgNoDataFound = false
      const charts = [
        this.axisX, this.axisY,
        this.consumptionAxisX, this.consumptionAxisY,
        this.fuelAxisX, this.fuelAxisY,
        this.batteryVoltageAxisX, this.batteryVoltageAxisY,
        this.dayErrorsAxisX, this.dayErrorsAxisY,
        this.fuelPressureAxisX, this.fuelPressureAxisY,
        this.engineLoadAxisX, this.engineLoadAxisY,
        this.engineOilPressureAxisX, this.engineOilPressureAxisY,
        this.engineCoolantTempAxisX, this.engineCoolantTempAxisY,
        this.hydraulicPressureAxisX, this.hydraulicPressureAxisY,
        this.hydraulicOilTempAxisX, this.hydraulicOilTempAxisY,
        this.powerBatteryChargeAxisX, this.powerBatteryChargeAxisY,
        this.powerBatteryLifeAxisX, this.powerBatteryLifeAxisY,
        this.powerBatteryTemperatureAxisX, this.powerBatteryTemperatureAxisY,
        this.powerBatteryVoltsAxisX, this.powerBatteryVoltsAxisY,
        this.speedAxisX, this.speedAxisY,
        this.transmissionTempAxisX, this.transmissionTempAxisY,
        this.turboPressureAxisX, this.turboPressureAxisY,
        this.statusValue
      ]
      const hasPositiveValues = this.checkChartsForPositiveValues(charts);

      if(!hasPositiveValues && !this.isLoadingDaily) {
        this.showMsgNoDataFound = true
        this.showCalendar = false
      }

    },

    checkChartsForPositiveValues(arrays) {
      for (let i = 0; i < arrays.length; i++) {
        const array = arrays[i];
        if (array.some(value => value > 0)) {
          return true;
        }
      }
      return false;
    },

    fetchDailyWorkHours() {

      /*
      var date = new Date();
      const firstDay = new Date(date.getFullYear(), date.getMonth(), 1).toISOString();
      const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString();
      */
      this.isLoadingDaily = true
      this.elementVisible = false;

      this.axisX = [];
      this.axisY = [];

      this.consumptionAxisX = []
      this.consumptionAxisY = []

      this.fuelAxisX = []
      this.fuelAxisY = []

      this.batteryVoltageAxisX = []
      this.batteryVoltageAxisY = []
      this.dayErrorsAxisX = []
      this.dayErrorsAxisY = []
      this.fuelPressureAxisX = []
      this.fuelPressureAxisY = []
      this.engineLoadAxisX = []
      this.engineLoadAxisY = []
      this.engineOilPressureAxisX = []
      this.engineOilPressureAxisY = []
      this.engineCoolantTempAxisX = []
      this.engineCoolantTempAxisY = []
      this.hydraulicPressureAxisX = []
      this.hydraulicPressureAxisY = []
      this.hydraulicOilTempAxisX = []
      this.hydraulicOilTempAxisY = []
      this.powerBatteryChargeAxisX = []
      this.powerBatteryChargeAxisY = []
      this.powerBatteryLifeAxisX = []
      this.powerBatteryLifeAxisY = []
      this.powerBatteryTemperatureAxisX = []
      this.powerBatteryTemperatureAxisY = []
      this.powerBatteryVoltsAxisX = []
      this.powerBatteryVoltsAxisY = []
      this.speedAxisX = []
      this.speedAxisY = []
      this.transmissionTempAxisX = []
      this.transmissionTempAxisY = []
      this.turboPressureAxisX = []
      this.turboPressureAxisY = []

      this.statusValue = []
      this.showCalendar = false

      store
          .dispatch('app-queries/fetchDailyWorkSummary', {
            id: router.currentRoute.params.id,
            dtS: this.dtS,
            dtE: this.dtE
          })
          .then(res => {
            let arrEvents = []

            this.statusLabels = [this.$t('Proper'), this.$t('Iddle'), this.$t('Severe')]
            this.statusValue = [
              parseFloat(res.data.normalUsePercentage.toFixed(2)),
              parseFloat(res.data.iddleUsePercentage.toFixed(2)),
              parseFloat(res.data.severeUsePercentage.toFixed(2))
            ]

            this.metricsPercentages = []
            this.metricsLabels = []
            if (res.data.lowBatteryPercentage > 0) {
              this.metricsPercentages.push(parseFloat(res.data.lowBatteryPercentage.toFixed(2)))
              this.metricsLabels.push(this.$t('Battery low'))
            }
            if (res.data.movingPercentage > 0) {
              this.metricsPercentages.push(parseFloat(res.data.movingPercentage.toFixed(2)))
              this.metricsLabels.push(this.$t('In movement'))
            }
            if (res.data.panicPercentage > 0) {
              this.metricsPercentages.push(parseFloat(res.data.panicPercentage.toFixed(2)))
              this.metricsLabels.push(this.$t('Panic'))
            }

            res.data.data.map((node, i) => {
              const formattedDate = this.formatDate(new Date(node.date))

              this.axisY.push(node.hours)
              this.axisX.push(formattedDate)

              this.consumptionAxisX.push(formattedDate)
              this.consumptionAxisY.push(node.meanFuelConsumption)

              this.fuelAxisX.push(formattedDate)
              this.fuelAxisY.push(node.fuelLevel)

              this.batteryVoltageAxisX.push(formattedDate)
              this.batteryVoltageAxisY.push(node.batteryVoltage)
              this.dayErrorsAxisX.push(formattedDate)
              this.dayErrorsAxisY.push(node.dayErrors)
              this.fuelPressureAxisX.push(formattedDate)
              this.fuelPressureAxisY.push(node.fuelPressure)
              this.engineLoadAxisX.push(formattedDate)
              this.engineLoadAxisY.push(node.engineLoad)
              this.engineOilPressureAxisX.push(formattedDate)
              this.engineOilPressureAxisY.push(node.engineOilPressure)
              this.engineCoolantTempAxisX.push(formattedDate)
              this.engineCoolantTempAxisY.push(node.engineCoolantTemp)
              this.hydraulicPressureAxisX.push(formattedDate)
              this.hydraulicPressureAxisY.push(node.hydraulicPressure)
              this.hydraulicOilTempAxisX.push(formattedDate)
              this.hydraulicOilTempAxisY.push(node.hydraulicOilTemp)
              this.powerBatteryChargeAxisX.push(formattedDate)
              this.powerBatteryChargeAxisY.push(node.powerBatteryCharge)
              this.powerBatteryLifeAxisX.push(formattedDate)
              this.powerBatteryLifeAxisY.push(node.powerBatteryLife)
              this.powerBatteryTemperatureAxisX.push(formattedDate)
              this.powerBatteryTemperatureAxisY.push(node.powerBatteryTemperature)
              this.powerBatteryVoltsAxisX.push(formattedDate)
              this.powerBatteryVoltsAxisY.push(node.powerBatteryVolts)
              this.speedAxisX.push(formattedDate)
              this.speedAxisY.push(node.speed)
              this.transmissionTempAxisX.push(formattedDate)
              this.transmissionTempAxisY.push(node.transmissionTemp)
              this.turboPressureAxisX.push(formattedDate)
              this.turboPressureAxisY.push(node.turboPressure)

              if (node.hours > 0) {
                let event = {
                  title: `Horas Trabalhadas: ${node.hours}`,
                  start: new Date(node.date),
                  allDay: true,
                  className: 'bg-primary text-white',
                }

                arrEvents.push(event)
              }
              this.isLoadingDaily = false
              this.elementVisible = true;
            });

            this.calendarOptions.events = arrEvents
            this.needShowMsgNoDataFound()
            if (this.axisY.some(valor => valor > 0)) this.showCalendar = true
            //this.showCalendar = true
          })
          .catch((e) => {
            this.showSnackbar({
              title: this.$t('Error fetching report'),
              icon: 'AlertTriangleIcon',
              variant: 'danger'
            })
            this.isLoadingDaily = false
            this.elementVisible = true;
          })

    },
    showSnackbar(config = {title: '', icon: '', variant: 'info'}) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: config.title,
          icon: config.icon,
          variant: config.variant,
        },
      })
    },

    fetchAsset() {
      // fetchAsset
      store
          .dispatch('app-queries/fetchAsset', {
            id: router.currentRoute.params.id

          })
          .then(response => {

            this.identifier = response.data.plate ? response.data.plate : response.data.chassisNumber
            this.imgUrl = this.resolveIcon(response.data.assetType);
            this.modelInfo = `${response.data.modelIdName ? response.data.modelIdName : response.data.modelName} ${response.data.modelYear}`
            this.color = response.data.color
          })
          .catch((e) => {
            console.log(e);
            console.log('erro no fetchAsset');

          })
    },

    incrementMonth(month) {
      const currentDate = new Date();
      const newDate = new Date();
      newDate.setMonth(currentDate.getMonth() + month);
      return newDate;

    },

    startDates() {
      const newDate = this.incrementMonth(-1)
      this.dtS = new Date(newDate.getFullYear(), newDate.getMonth(), 1).toISOString();
      this.dtE = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 0).toISOString();


    },

    populateDropdownMonth() {

      for (var i = 1; i > -4; i--) {
        const newDate = this.incrementMonth(i)
        const month = newDate.getMonth() + 1;
        const year = newDate.getFullYear()

        this.optMonth.push({
          text: `${month < 10 ? '0' : ''}${month}/${year}`,
          value: newDate
        })
      }
    },

    selectMonth(dt, text) {
      
      this.month = text


      this.dtS = new Date(dt.getFullYear(), dt.getMonth(), 1).toISOString();
      this.dtE = new Date(dt.getFullYear(), dt.getMonth() + 1, 0).toISOString();

      // Dividindo a data original em dia e mês
      const partesData = text.split("/");

      // Obtendo o dia e mês
      const month = partesData[0];
      const year = partesData[1];

      // Criando a nova data no formato "yyyy-mm-dd"
      const newDate = `${year}-${month}`;
      console.log('Nova Data: ', newDate)


      
      
      
      if(this.$refs.refCalendar){
        this.calendarKey++
        const calendarApi = this.$refs.refCalendar.getApi();
        calendarApi.gotoDate(newDate);
      }

      // this.$nextTick(() => {
      //     this.$refs.refCalendar.getApi().gotoDate(novaData)
      // })

      this.fetchDailyWorkHours()
      this.fetchPlaces()
    }

  },

  setup() {
    const QUERIES_APP_STORE_MODULE_NAME = 'app-queries'

    const myId = router.currentRoute.params.id

    const identifier = ref('')
    const imgUrl = ref(0)
    const modelInfo = ref('')
    const color = ref('')

    const axisX = ref([])
    const axisY = ref([])

    const consumptionAxisX = ref([])
    const consumptionAxisY = ref([])

    const fuelAxisX = ref([])
    const fuelAxisY = ref([])

    const batteryVoltageAxisX = ref([])
    const batteryVoltageAxisY = ref([])

    const dayErrorsAxisX = ref([])
    const dayErrorsAxisY = ref([])

    const fuelPressureAxisX = ref([])
    const fuelPressureAxisY = ref([])

    const engineLoadAxisX = ref([])
    const engineLoadAxisY = ref([])

    const engineOilPressureAxisX = ref([])
    const engineOilPressureAxisY = ref([])

    const engineCoolantTempAxisX = ref([])
    const engineCoolantTempAxisY = ref([])

    const hydraulicPressureAxisX = ref([])
    const hydraulicPressureAxisY = ref([])

    const hydraulicOilTempAxisX = ref([])
    const hydraulicOilTempAxisY = ref([])

    const powerBatteryChargeAxisX = ref([])
    const powerBatteryChargeAxisY = ref([])

    const powerBatteryLifeAxisX = ref([])
    const powerBatteryLifeAxisY = ref([])

    const powerBatteryTemperatureAxisX = ref([])
    const powerBatteryTemperatureAxisY = ref([])

    const powerBatteryVoltsAxisX = ref([])
    const powerBatteryVoltsAxisY = ref([])

    const speedAxisX = ref([])
    const speedAxisY = ref([])

    const transmissionTempAxisX = ref([])
    const transmissionTempAxisY = ref([])

    const turboPressureAxisX = ref([])
    const turboPressureAxisY = ref([])

    const statusLabels = ref([])
    const statusValue = ref([])

    const metricsPercentages = ref([])
    const metricsLabels = ref([])

    // Register module
    if (!store.hasModule(QUERIES_APP_STORE_MODULE_NAME)) store.registerModule(QUERIES_APP_STORE_MODULE_NAME, queriesStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(QUERIES_APP_STORE_MODULE_NAME)) store.unregisterModule(QUERIES_APP_STORE_MODULE_NAME)
    })

    return {
      QUERIES_APP_STORE_MODULE_NAME,
      identifier,
      imgUrl,
      modelInfo,
      color,
      axisX,
      axisY,
      myId,
      statusLabels,
      statusValue,
      consumptionAxisX,
      consumptionAxisY,
      fuelAxisX,
      fuelAxisY,
      metricsPercentages,
      metricsLabels,
      batteryVoltageAxisX,
      batteryVoltageAxisY,
      dayErrorsAxisX,
      dayErrorsAxisY,
      fuelPressureAxisX,
      fuelPressureAxisY,
      engineLoadAxisX,
      engineLoadAxisY,
      engineOilPressureAxisX,
      engineOilPressureAxisY,
      engineCoolantTempAxisX,
      engineCoolantTempAxisY,
      hydraulicPressureAxisX,
      hydraulicPressureAxisY,
      hydraulicOilTempAxisX,
      hydraulicOilTempAxisY,
      powerBatteryChargeAxisX,
      powerBatteryChargeAxisY,
      powerBatteryLifeAxisX,
      powerBatteryLifeAxisY,
      powerBatteryTemperatureAxisX,
      powerBatteryTemperatureAxisY,
      powerBatteryVoltsAxisX,
      powerBatteryVoltsAxisY,
      speedAxisX,
      speedAxisY,
      transmissionTempAxisX,
      transmissionTempAxisY,
      turboPressureAxisX,
      turboPressureAxisY,
    }
  }

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/map-leaflet.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
//@import "@core/scss/vue/apps/calendar.scss";
</style>
<style lang="scss" scoped>
.card-img {
  max-width: 200px !important;
  max-height: 200px !important;
  min-width: 50px !important;
  min-height: 50px !important;
}

.card-text {
  font-size: 0.95em !important;
}

.noconnection {
  color: #726f7f !important;
}

.no-gutters {
  margin: -1.5rem !important;
}

.div-img {
  background-color: #F3F2F7 !important;
}

.heat-map {
  width: 100%;
  height: 500px;
}

.fc-toolbar-title {
  text-align: left !important;
}

.calendar-hidden {
  visibility: hidden;

}

@media (max-width: 576px) {
  .custom-title {
    font-size: 14px !important; /* Defina o tamanho da fonte desejado para telas sm ou menores */
  }
}
</style>
